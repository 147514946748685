import React from "react";
import "../css/bottom_contact.css";

export default function() {
    return (
        <div className="bottom-contact--container">
            <div className="bottom-contact--mid">
                <a className="bottom-contact--scmedia" target="_blank" href="https://twitter.com/TerroidYT"><i class="fa-brands fa-twitter"></i></a>
                <a className="bottom-contact--scmedia" target="_blank" href="https://www.instagram.com/bhairavaskanda/"><i class="fa-brands fa-instagram"></i></a>
                <a className="bottom-contact--scmedia" target="_blank" href="https://github.com/skandabhairava"><i class="fa-brands fa-github"></i></a>
            </div>
        </div>
    )
}