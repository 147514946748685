import React from "react";
import "../css/main_content.css";

export default function() {
    return (
        <div className="main-content--container">
            <h1 className="main-content--title">About</h1>
            <p className="main-content--text">I am a backend python developer learning frontend. I mainly work with python, with a particular interest of making things simple and automating daily tasks</p>
            <h1 className="main-content--title">Interest</h1>
            <p className="main-content--text">Solving Challenges. Natural Language(Linguistics). Automating simple tasks. Gaming. Reading Books. Being Up-To-Date with technology, and much more</p>
        </div>
    )
}